.App {
  text-align: center;
}

@font-face {
  font-family: 'Typewriter';
  src: local('Typewriter'), url(./fonts/Typewriter.ttf) format('truetype');
}

html{
  background-color: rgb(40, 56, 61);
}

.home-page{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: white;
}


.logo{
  padding: 1%;
  justify-content: center;
  max-width: 5%;
  height: auto;
}

.iconButton span.linkClass{
  cursor: pointer;
  display: inline;
  position: relative;
  transition: 0.5s;
}

.iconButton span.linkClass:after {
  align-items: center; 
  position: absolute;
  opacity: 0;
  top: 30%;
  right: 0px;
  transition: 0.5s;
  content: 'LinkedIn';
}

.iconButton:hover span.linkClass {
  padding-right: 100px;
}

.iconButton:hover span.linkClass:after {
  opacity: 1;
  right: -10;
}

.iconButton span.gitClass{
  cursor: pointer;
  display: inline;
  position: relative;
  transition: 0.5s;
}

.iconButton span.gitClass:after {
  align-items: center; 
  position: absolute;
  opacity: 0;
  top: 30%;
  right: 0px;
  transition: 0.5s;
  content: 'Github';
}

.iconButton:hover span.gitClass {
  padding-right: 100px;
}

.iconButton:hover span.gitClass:after {
  opacity: 1;
  right: -10;
}

.iconButton span.resumeClass{
  cursor: pointer;
  display: inline;
  position: relative;
  transition: 0.5s;
}

.iconButton span.resumeClass:after {
  align-items: center; 
  position: absolute;
  opacity: 0;
  top: 30%;
  right: 0px;
  transition: 0.5s;
  content: 'Résumé';
}

.iconButton:hover span.resumeClass {
  padding-right: 100px;
}

.iconButton:hover span.resumeClass:after {
  opacity: 1;
  right: -10;
}

.project-card{
  display: flex;
  max-width: 100%;
  height: auto;
}

.project-child{
  flex: 1;
  padding: 4%;
}

.project-child2{
  flex: 1;
  align-items: center;
  padding: 4%;
}
.project-image{
  width: 90vh;
}

.project-title{
  text-align: center;
  color: white;
}

.about{
  color: white;
}

.TextField.MuiInputBase-input{
  outline-color: white;
}

.home-title{
  font-size: 400%;
  font-family: 'Typewriter';
  white-space: nowrap;
  overflow: hidden; 
  padding-top: 2em;
  border-right: solid 3px rgba(0,255,0,.75);
  padding: 40px;
}

.home-desc{
  font-size: 175%;
  font-family: 'Typewriter';
}

.typewriter {
  font-family: Courier, monospace;
display: inline-block;
}

.typewriter-text {
  margin-top: 2em;
  display: inline-block;
  overflow: hidden;
  letter-spacing: 2px;
 animation: typing 2.5s steps(30, end), blink .75s step-end infinite;
  white-space: nowrap;
  font-size: 75px;
  font-weight: 700;
  border-right: 30px solid white;
  box-sizing: border-box;
  font-family: "Typewriter";
}

@keyframes typing {
  from { 
      width: 0% 
  }
  to { 
      width: 100% 
  }
}

@keyframes blink {
  from, to { 
      border-color: transparent 
  }
  50% { 
      border-color: white; 
  }
}
